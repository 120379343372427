/*!
 * Bootstrap v4.0.0-beta (https://getbootstrap.com)
 * Copyright 2011-2017 The Bootstrap Authors
 * Copyright 2011-2017 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

@import "functions";
@import "variables";
@import "mixins";

/* NAVIBAR SETTINGS OVERWRITED
-------------------------------------------------- */

// Navbar
//
// Provide a static navbar from which we expand to create full-width, fixed, and
// other navbar variations.

.navbar {
	z-index: 10;
	position: relative;
	display: flex;
	flex-wrap: wrap; // allow us to do the line break for collapsing content
	align-items: center;
	justify-content: space-between; // space out brand from logo
	// padding: $navbar-padding-y $navbar-padding-x;
	padding: 0 0;
	padding-top: 94px;

	// Because flex properties aren't inherited, we need to redeclare these first
	// few properities so that content nested within behave properly.
	> .container,
	> .container-fluid {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
	}
}


// Navbar brand
//
// Used for brand, project, or site names.

.navbar-brand {
	display: inline-block;
	padding-top: 0; //$navbar-brand-padding-y;
	padding-bottom: 0; //$navbar-brand-padding-y;
	margin-right: 0; //$navbar-padding-x;
	font-size: $navbar-brand-font-size;
	line-height: 94px; //inherit;
	white-space: nowrap;

	height: 94px;
	min-width: 644px;
	text-indent: -99999px;
	z-index: 20;
	@include hover-focus {
		text-decoration: none;
	}
}


// Navbar nav
//
// Custom navbar navigation (doesn't require `.nav`, but does make use of `.nav-link`).

.navbar-nav {
	display: flex;
	flex-direction: column; // cannot use `inherit` to get the `.navbar`s value
	padding-left: 0;
	margin-bottom: 0;
	list-style: none;

	.nav-link {
		padding-right: 0;
		padding-left: 0;
		line-height: 94px;
	}

	.dropdown-menu {
		position: static;
		float: none;
	}
}


// Navbar text
//
//

.navbar-text {
	display: inline-block;
	padding-top: .425rem; //$nav-link-padding-y;
	padding-bottom: .425rem //$nav-link-padding-y;
}


// Responsive navbar
//
// Custom styles for responsive collapsing and toggling of navbar contents.
// Powered by the collapse Bootstrap JavaScript plugin.

// When collapsed, prevent the toggleable navbar contents from appearing in
// the default flexbox row orienation. Requires the use of `flex-wrap: wrap`
// on the `.navbar` parent.
.navbar-collapse {
	flex-basis: 100%;
	// For always expanded or extra full navbars, ensure content aligns itself
	// properly vertically. Can be easily overridden with flex utilities.
  justify-content: flex-end !important;
	align-items: center;
}

// Button for toggling the navbar when in its collapsed state
.navbar-toggler {
	padding: $navbar-toggler-padding-y $navbar-toggler-padding-x;
	font-size: $navbar-toggler-font-size;
	line-height: 1;
	background: transparent; // remove default button style
	border: $border-width solid transparent; // remove default button style
	@include border-radius($navbar-toggler-border-radius);

	@include hover-focus {
		text-decoration: none;
	}
}

// Keep as a separate element so folks can easily override it with another icon
// or image file as needed.
.navbar-toggler-icon {
	display: inline-block;
	width: 1.5em;
	height: 1.5em;
	vertical-align: middle;
	content: "";
	background: no-repeat center center;
	background-size: 100% 100%;
}

// Generate series of `.navbar-expand-*` responsive classes for configuring
// where your navbar collapses.
.navbar-expand {
	@each $breakpoint in map-keys($grid-breakpoints) {
		$next: breakpoint-next($breakpoint, $grid-breakpoints);
		$infix: breakpoint-infix($next, $grid-breakpoints);

		&#{$infix} {
			@include media-breakpoint-down($breakpoint) {
				> .container,
				> .container-fluid {
					padding-right: 0;
					padding-left: 0;
				}
			}

			@include media-breakpoint-up($next) {
				flex-direction: row;
				flex-wrap: nowrap;
				justify-content: flex-start;

				.navbar-nav {
					flex-direction: row;

					.nav-item.inverse-red {
						background: red;
						opacity: 1;
						a.nav-link {
							color: #fff;
						&:hover {
							color: #000;
						}
						}
					}

					.dropdown-menu {
						position: absolute;
					}

					.dropdown-menu-right {
						right: 0;
						left: auto; // Reset the default from `.dropdown-menu`
					}

					.nav-link {
						padding-right: 1.5rem; //.5rem;
						padding-left: 1.5rem; //.5rem;
					}
				}

				// For nesting containers, have to redeclare for alignment purposes
				> .container,
				> .container-fluid {
					flex-wrap: nowrap;
				}

				// scss-lint:disable ImportantRule
				.navbar-collapse {
					display: flex !important;
				}
				// scss-lint:enable ImportantRule

				.navbar-toggler {
					display: none;
				}
			}
		}
	}
}


// Navbar themes
//
// Styles for switching between navbars with light or dark background.
// Dark links against a light background
.navbar-light {
	.navbar-brand {
		color: $navbar-light-active-color;

		@include hover-focus {
			color: $navbar-light-active-color;
		}
	}

	.navbar-nav {
		.nav-link {
			color: #000; //$navbar-light-color;

			@include hover-focus {
				color: $navbar-light-hover-color;
			}

			&.disabled {
				color: $navbar-light-disabled-color;
			}
		}

		.show > .nav-link,
		.active > .nav-link,
		.nav-link.show,
		.nav-link.active {
			color: $navbar-light-active-color;
		}
	}

	.navbar-toggler {
		color: $navbar-light-color;
		border-color: $navbar-light-toggler-border-color;
	}

	.navbar-toggler-icon {
		background-image: $navbar-light-toggler-icon-bg;
	}

	.navbar-text {
		color: $navbar-light-color;
	}
}



/* ORIGINAL COMMON SETTINGS
-------------------------------------------------- */
body {
	background-color: #000;
	color: #666;
}

.body-wrapper {
	background-color: #000;
}

em,
strong {
	font-style: normal;
}
/* JUMBOTRON
------------------------------ */
.jumbotron {
	margin-bottom: 0;
	min-height: 300px;
	position: relative;
	.container {
		color: #fff;
		.tagline {
			margin-top: 2rem;
			font-family: Futura, "Trebuchet MS", Arial, sans-serif;
			letter-spacing: 0.1em;
			font-size: 1rem;
			line-height: 1.2;
			margin-bottom: 0;
		}
		.category-title {
			font-family: Futura, "Trebuchet MS", Arial, sans-serif;
			font-size: 2rem;
			line-height: 1.2;
		}
		.scrolldown {
			position: absolute;
			bottom: 0;
			margin-left: -66px;
			left: 50%;
		}
	}
}


.btn-org1 {
	border-radius: 3rem;
	border-width: 2px;
&:hover {
	color: #fff;
	background-color: #c00;
	border-color: #c00;
}
}

.btn-org2 {
	border-radius: 0;
	color: #fff;
	background-color: #c00;
	border-color: #c00;
	border-width: 2px;
&:hover {
	color: #c00;
	background-color: transparent;
	border-color: #c00;
}
}

.btn-org3 {
	width: 540px;
	height: 88px;
	border-radius: 0;
	color: #fff;
	background-color: transparent;
	border-color: #fff;
	border-width: 3px;
&:hover {
	color: #c00;
	background-color: transparent;
	border-color: #c00;
}
}


.btn-org4 {
	width: 350px;
	height: 80px;
	border-radius: 0;
	color: #fff;
	background-color: #c00;
	border-color: #fff;
	border-width: 3px;
&:hover {
	color: #c00;
	background-color: transparent;
	border-color: #c00;
}
}

.nopd {
	padding: 0;
}

.nopd {
	margin: 0;
}

.mxw1200 {
	max-width: 1200px;
}

.mxw100p {
	max-width: 100%;
}

.unit-hero {
	max-width: 1100px;
	margin: 0 auto;
	padding-bottom: 6rem;
	img {
		width: 100%;
	}
}

.contents {
	padding-top: 2rem;
	background-color: #fff;
}

.wp_social_bookmarking_light {
	display: flex;
	justify-content: flex-end;
}

/* COMPANY INFORMATION LINK
------------------------------ */
.comlink {
	background-color: #fff;
	padding-bottom: 3rem;
	.container {
		padding-right: 0;
		padding-left: 0;
		.comlink-title {
			background-color: #fff;
			h2 {
				margin: 88px auto 52px;
			}
		}
		.goto-about {
			.about-button {
				margin: 20px auto 20px;
			}
		}
		.about-cards {
			margin-bottom: 20px;
			display: flex;
			flex-wrap: wrap;
			.a-card {
				margin-left: 10px;
				margin-right: 10px;
				height: 100%;
				border: 1px solid #ccc;
				border-radius: .75rem;
				// padding-right: 10px;
				.a-card-body {
					padding: 1rem;
					text-align: center;
				}
				.a-card-title {
					font-size: 1rem;
					font-weight: bold;
					a {
						color: #000;
					}
				}
				.a-card-text {
					font-size: 1rem;
				}
			}
			.a-card-img {
				padding-top: 3rem;
				img {
					// width: 90px;
					max-width: 100%;
					display: block;
					margin: 0 auto;
				}
			}
		}
	}
}

/* FOOTER MODULE
------------------------------ */
.footer {
	color: #fff;
	padding-top: 2.5rem;
	padding-bottom: 1rem;
	margin-bottom: 0;
	p {
		font-size: .8125rem;
	}
	.footer-logo {

	}
	.footer-address {
		margin-top: 8px;
		span {
			font-weight: bold;
		}
	}
	.footer-tel {
		margin-left: 1rem;
	}
	.copyright {
		margin-top: 2rem;
	}
}

/* BOTTOM INFORMATION MODULE
------------------------------ */
.information {
	.info-unit {
		margin: 0;
		padding: 0;

		width: 100%;
		position: relative;
		color: #fff;
		img.info-image {
			margin: 0;
			position: relative;
			width: 100%;
		}
		.info-content {
			h2.info-title {
				font-weight: normal;
				letter-spacing: 0.1em;
				span {
					font-size: 1rem;
					display: block;
				}
			}
			position: absolute;
			top: 2rem;
			text-align: center;
			padding-left: 1rem;
			padding-right: 1rem;
		}
		.info-button {
			position: absolute;
			text-align: center;
			left: 0;
			right: 0;
			bottom: 3rem;
			a {
				line-height: 4.375rem;
			}
		}
	}
}

/* BREADCRUMBS MODULE
------------------------------ */
.breadcrumbs {
	background-color: #fff;
	padding-bottom: 3rem;
	.breadcrumb {
		margin-bottom: 0;
		background-color: #fff;
	}
}

.breadcrumb-item {
	font-size: .875rem;
	a {
		color: #666;
	}
	+ .breadcrumb-item::before {
		content: ">>";
	}
	&.active {
		color: #666;
	}
}
.page-title,
.unit-title,
.subunit-title {
	background-color: #fff;
	margin-bottom: 0;
	padding-bottom: 1rem;
	text-align: center;
	h3 {
		margin-bottom: 0;
	}
}


/* RESPONSIVE CSS
-------------------------------------------------- */
// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
	.information {
		display: flex;
		.info-unit {
			width: 100%;
			img.info-image {
				width: 100%;
			}
			.info-content {
				width: 100%;
				top: 1.5rem;
			}
			.info-button {
				bottom: 1rem;
			}
		}
	}
}


// Extra large devices (extra large desktops)
@media (min-width: 1440px) {
	.information {
		.info-unit {
			.info-content {
				top: 3rem;
			}
			.info-button {
				bottom: 2rem;
			}
		}
	}
}


// Medium devices (tablets, less than 1200px)
@media (max-width: 1199px) {
	.navbar-nav {
		.nav-link {
			line-height: 36px;
		}
		.nav-item {
			text-align: center;
			border-bottom: 1px solid #ccc;
		&.inverse-red {
			background: red;
			border-bottom: none;
			a.nav-link {
				color: #fff;
			&:hover {
				color: #ccc;
			}
			}
		}
		}
	}

	.information {
		flex-direction: column;
		width: 100%;
		.info-unit {
			width: 100%;
			height: auto;
			img.info-image {
				width: 100%;
				height: auto;
			}
			.info-content {
				width: 100%;
				top: 5rem;
				p {
					margin-top: 0;
					margin-left: 1rem;
					margin-right: 1rem;
				}
			}
			.info-button {
				bottom: 5rem;
			}
		}
	}

	.unit-hero {
		padding-bottom: 3rem;
	}
}

.nav-item.inverse-red {
	background: red;
	a.nav-link {
		color: #fff;
	&:hover {
		color: #ccc;
	}
	}
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {

}

// Medium devices (tablets, less than 992px)
@media (max-width: 991px) {
	.information {
		.info-unit {
			.info-content {
				top: 3rem;
			}
			.info-button {
				bottom: 3rem;
			}
		}
	}


.comlink {
	padding-bottom: 2rem;
	.container {
		padding-right: 15px;
		padding-left: 15px;
		.comlink-title {
			h2 {
				margin: 4rem auto 3rem;
			}
		}
		.about-cards {
			margin-bottom: 20px;
			display: flex;
			flex-wrap: wrap;
			.card-wrap {
				margin-bottom: 1rem;
			}
			.a-card {
				margin-left: 0;
				margin-right: 0;
				height: 100%;
				.a-card-body {
					padding: 2rem 1rem;
					text-align: left;
				}
			}
			.a-card-img {
				padding-top: 2.5rem;
				img {
					// width: 90px;
					max-width: 100%;
					display: block;
					margin: 0 auto;
					padding-left: .5rem;
				}
			}
		}
	}
}




}


// Extra small devices (portrait phones, less than 767px)
@media (max-width: 767px) {
	.information {
		.info-unit {
			width: 100%;
			img.info-image {
				width: 100%;
			}
			.info-content {
				top: 3rem;
				h2.info-title {
					display: none;
				}
				p {
					// font-size: .8rem;
					margin-top: 0;
					margin-left: 0;
					margin-right: 0;
				}
			}
			.info-button {
				bottom: 3rem;
			}
		}
	}

	.footer {
		.footer-logo {
			img {
				width: 80%;
			}
		}
		.footer-address {
			.footer-tel {
				display: block;
				margin-left: 0;
			}
		}
		.copyright {
			margin-top: 1rem;
			.arr {
				display: block;
			}
		}
	}
}

@media (max-width: 600px) {
	.information {
		.info-unit {
			.info-content {
				top: 1rem;
				p {
					font-size: .875rem;
				}
			}
			.info-button {
				bottom: 1rem;

				a {
					font-size: 1.1rem;
					width: 80%;
					height: 240%;
					line-height: 240%;
				}
			}
		}
	}
}